<template>
<div class="border px-6 py-4 w-100vw light-blue darken-4 f-white footer_box fade-in opacity0">
    <div class="footer_logo">
        <router-link to="/#top"><span class="f-12 bold">{{Info.company}}</span></router-link><br>
        <span><span v-if="Info.zip" class="pr-2">〒{{Info.zip}}</span>{{Info.address}}</span>
    </div>
    <div class="footer_menu disno-xs">
        <router-link :to="r.path" class="px-8" :class="{underline:$route.path==r.path,noevent:$route.path==r.path}" v-for="r in rinks" :key="r.path">{{r.name}}</router-link>
    </div>
    <div class="clear" />
</div>
</template>

<script>
import FooterMenu from "@/mixins/footerMenu"

export default {
    mixins:[FooterMenu],
    computed: {
        Info() {
            return this.$store.getters["user/info"]
        },
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/footer.scss";
</style>
