export default {
    data() {
        return {
            rinks: [
                {
                    name: '会社概要',
                    path: "/company"
                },
                {
                    name: 'プライバシーポリシー',
                    path: "/privacy"
                },
                {
                    name: 'お問合せ',
                    path: "/contact"
                },
            ]
        }
    },
}