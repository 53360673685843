import Vue from "vue";
import VueRouter from "vue-router";


Vue.use(VueRouter);

const routes = [

    {
        path: "/",
        name: "top",
        component: () => import("../views/top.vue"),
    },
    {
        path: "/real_estate",
        name: "realEstate",
        component: () => import("../views/realEstate.vue"),
    },
    // {
    //     path: "/sale",
    //     name: "sale",
    //     component: () => import("../views/sale.vue"),
    // },
    // {
    //     path: "/buy",
    //     name: "buy",
    //     component: () => import("../views/buy.vue"),
    // },
    {
        path: "/staff",
        name: "staff",
        component: () => import("../views/staff.vue"),
    },
    {
        path: "/hikari",
        name: "hikari",
        component: () => import("../views/Home.vue"),
    },
    {
        path: "/estimate",
        name: "estimate",
        component: () => import("../views/estimate.vue"),
    },
    {
        path: "/estate",
        name: "estate",
        component: () => import("../views/estate.vue"),
    },
    {
        path: "/comfirm",
        name: "comfirm",
        component: () => import("../views/comfirm.vue"),
    },
    {
        path: "/thankyou",
        name: "thankyou",
        component: () => import("../views/thankyou.vue"),
    },
    {
        path: "/company",
        name: "company",
        component: () => import("../views/company.vue"),
    },
    {
        path: "/contact",
        name: "contact",
        component: () => import("../views/contact.vue"),
    },

    {
        path: "/privacy",
        name: "privacy",
        component: () => import("../views/privacy.vue"),
    },

    {
        path: "/estate/count",
        name: "EstateCount",
        component: () => import("../views/estate/count.vue"),
    },

    {
        path: "/estate/rent/detail/:id",
        name: "EstateDetail",
        component: () => import("../views/estate/detail.vue"),
    },
    {
        path: "/estate/buy/detail/:id",
        name: "EstateDetail",
        component: () => import("../views/estate/detail.vue"),
    },
    {
        path: "/estate/buy/used/mansion",
        name: "EstateUsedMansion",
        component: () => import("../views/estate/search.vue"),
    },

    {
        path: "/estate/buy/used/kodate",
        name: "EstateUsedKodate",
        component: () => import("../views/estate/search.vue"),
    },

    {
        path: "/estate/buy/new/kodate",
        name: "EstateNewKodate",
        component: () => import("../views/estate/search.vue"),
    },
    {
        path: "/estate/rent/sumai",
        name: "EstateSumai",
        component: () => import("../views/estate/search.vue"),
    },
    {
        path: "/estate/rent/store",
        name: "EstateStore",
        component: () => import("../views/estate/search.vue"),
    },
    // {
    //     path: "/user",
    //     name: "user",
    //     component: () => import("../views/user/index.vue"),
    // },
    {
        path: "/user/holiday",
        name: "userHoliday",
        component: () => import("../views/user/holiday.vue"),
    },
    {
        path: "/user/interval",
        name: "userInterval",
        component: () => import("../views/user/interval.vue"),
    },
    {
        path: "/user/price",
        name: "userPrice",
        component: () => import("../views/user/price.vue"),
    },
    {
        path: "/user/company",
        name: "userCompany",
        component: () => import("../views/user/company.vue"),
    },
    {
        path: "/user/payment",
        name: "userPayment",
        component: () => import("../views/user/payment.vue"),
    },
    {
        path: "/user/history",
        name: "userHistory",
        component: () => import("../views/user/history.vue"),
    },
    {
        path: "/user/contact",
        name: "userContact",
        component: () => import("../views/user/contact.vue"),
    },
    {
        path: "/user/account",
        name: "userAccount",
        component: () => import("../views/user/account.vue"),
    },
    {
        path: "/user/verify",
        name: "userVerify",
        component: () => import("../views/user/verify.vue"),
    },
    {
        path: "/user/station",
        name: "userStation",
        component: () => import("../views/user/station.vue"),
    },
    {
        path: "/user/estate",
        name: "userEstate",
        component: () => import("../views/user/estate.vue"),
    },
    {
        path: "/user/access",
        name: "userAccess",
        component: () => import("../views/user/access.vue"),
    },
    {
        path: '*',
        name: "notfound",
        component: () =>
            import('../views/notfound.vue'),
    },
];

// 
const router = new VueRouter({
    mode: "history",
    base: "/",
    routes,
    scrollBehavior: async (to) => {

        if (to.hash) {
            return { selector: to.hash, behavior: 'smooth' }

        }

        return { x: 0, y: 0 };
    },
});

import d from "@/d"
import store from "@/store";
let state = store.state;


import axios from "axios";
let count = 0

router.beforeEach(async (to, from, next) => {

    if ((location.hash != to.path && to.path == location.pathname && count > 0) || (to.path).indexOf('/user') != -1 || (location.pathname).indexOf('/user') != -1) {
        console.log()
    } else {
        count = 1
        const data = {
            to: to.path,
            from: location.pathname,
        };
         axios.post("/access/index", data);
    }


    let i = 0;
    while (typeof state.user.info.email !== "string") {
        await d.sleep(10);
        i += 1;
        if (i > 50) {
            break;
        }
    }
    if (to.path.indexOf("/user") != -1) {
        if (!state.user.user || typeof state.user.user.email !== "string") {
            window.location.href = "/login";
            return false;
        }
        if (to.path.indexOf("/user/estate") != -1 && (!state.user.user ||  state.user.user.email !== "info@crevo-co.jp")) {
            window.location.href = "/";
            return false;
        }

        if (state.user.user && state.user.user.email_verified == 2 && to.path.indexOf("/verify") != -1) {
            window.location.href = "/user";
            return false;
        }

        if (state.user.user && state.user.user.email_verified == 1 && to.path.indexOf("/verify") == -1) {
            window.location.href = "/user/verify";
            return false;
        }
    } else {

        if (!state.user.info || typeof state.user.info.email !== "string") {
            window.location.href = "/";
            return false;
        }

        if (from.name != "estimate" && from.name != "comfirm" && to.name == "estate") {
            window.location.href = "/estimate";
            return false;
        }

        if (from.name != "estate" && to.name == "comfirm") {
            window.location.href = "/estimate";
            return false;
        }

        if (from.name != "comfirm" && to.name == "thankyou") {
            window.location.href = "/";
            return false;
        }

    }

    if (to.path == '/' && to.hash != '' && from.path != '/') {
        await d.sleep(100)
    }

    next();
});

export default router;
