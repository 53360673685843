import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Vuetify from "vuetify"; // ←追加
import "vuetify/dist/vuetify.min.css"; // ←追加
import "@fortawesome/fontawesome-free/css/all.css";
import '@mdi/font/css/materialdesignicons.css' 
import store from "@/store";
import VueScrollTo from 'vue-scrollto'
 
Vue.use(VueScrollTo)

Vue.use(Vuetify); // ←追加
const vuetify = new Vuetify(); //←追加


import globalMixins from './mixins/url'
Vue.mixin(globalMixins);

require("@/assets/sass/style.scss");

Vue.config.productionTip = false;

export default new Vuetify({
  icons: {
    iconfont: "fa mdi",
  },
});

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
