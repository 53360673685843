<template>
<div class="relative z-200 w-100w">
    <v-app-bar hide-on-scroll fixed flat color="white">
        <div class="w-100 h-100">
            <div class="float-left">
                <router-link :to="{name:'hikari',hash:'#top'}" class=" noto9 company">
                    <span class=" f-16 f-12-xxs ">株式会社</span> <span class=" f-18 f-14-xxs">C<span class="f-orange">R</span>EVo</span>
                </router-link>
            </div>
            <router-link to="/estimate" class="disno-md">
                <div class="float-right bg-red f-white noto7 px-4 contact flex-center relative button">
                    <div class="f-12">
                        簡単お見積は こちら
                    </div>
                </div>
            </router-link>
            <div class="float-right menu  disno-md">
                <div class="float-left mx-6 noto7" v-for="menu in menus" :key="menu.a">
                 <div v-scroll-to="'#'+menu.a" class="f-10 pointer"  v-if="$route.path=='/hikari'">{{menu.text}}</div>
                 <router-link :to="'/hikari#'+menu.a" class="f-10 pointer" v-else>{{menu.text}}</router-link>
                </div>
                <div class="clear" />
            </div>
            <div class="float-right relative menu_bar dis-md">
                <div class="inline-block text-center" @click="drawer=true">
                    <v-icon>mdi-menu </v-icon>
                    <div class="f-08 relative ">MENU</div>
                </div>
            </div>
            <div class="clear" />
        </div>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" fixed temporary right>
        <div class="text-center">
            <router-link to="/estimate" @click="drawer=false">
                <div class=" bg-red f-white noto7  px-2 py-2  relative w-100">
                    <div class="f-12">
                        簡単お見積は こちら
                    </div>
                </div>
            </router-link>
            <div class="my-2 noto7" v-for="menu in menus" :key="menu.a" @click="drawer=false">
                <div v-scroll-to="'#'+menu.a" class="f-10 pointer" v-if="$route.path=='/hikari'">{{menu.text}}</div>
                 <router-link :to="'/hikari#'+menu.a" class="f-10 pointer" v-else>{{menu.text}}</router-link>
            </div>
            <hr class="my-4 mx-4">
            <div class="my-2 noto7" v-for="r in rinks" :key="r.path">
                <router-link :to="r.path" class="f-10" @click="drawer=false">{{r.name}}</router-link>
            </div>
        </div>
    </v-navigation-drawer>

</div>
</template>

<script>
import FooterMenu from "@/mixins/footerMenu"
export default {
    mixins: [FooterMenu],
    data() {
        return {
            drawer: false,
            menus: [{
                    text: "トップ",
                    a: "top"
                }, {
                    text: "光触媒とは？",
                    a: "what"
                },
                {
                    text: "選ばれる理由",
                    a: "point"
                },
                {
                    text: "実証試験",
                    a: "test"
                },
                {
                    text: "他社製品との違い",
                    a: "differ"
                },
                {
                    text: "施工手順",
                    a: "flow"
                },
            ],
        }
    },
    

}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/header.scss";
</style>
