<template>
  <div id="app" class="relative main" v-show="show" ref="main">
    <v-app>
      <div :class="{ opacity0: loading2 }">
        <div
          v-if="
            $route.path == '/hikari' ||
            $route.path == '/company' ||
            $route.path == '/privacy' ||
            $route.path == '/estimate' ||
            $route.path == '/estate' ||
            $route.path == '/comfirm' ||
            $route.path == '/thankyou' ||
            $route.path == '/contact'
          "
          :class="{ opacity0: loading }"
        >
          <Header />
          <!-- <div class="float-right text-right pr-3 pt-3 dis-sm" v-if="!userpath">
                <div class="text-center">
                    <div @click="drawer=true" class="pointer">
                        <v-icon class="f-20">fas fa-bars</v-icon>
                        <div>menu</div>
                    </div>
                </div>
            </div> -->
          <div class="clear" />
          <router-view class="relative pt-12 overflow-hidden" />
          <Footer v-if="!userpath && !loading" />
          <v-navigation-drawer v-model="drawer" absolute temporary>
            <h3 class="f-gray mb-6 pt-5">MENU</h3>
            <div v-for="r in rinks" :key="r.path" class="my-3">
              <router-link
                :to="r.path"
                :class="{
                  underline: $route.path == r.path,
                  noevent: $route.path == r.path,
                }"
                >{{ r.name }}</router-link
              >
            </div>
          </v-navigation-drawer>
        </div>
        <div
         
          :class="{
            'lighten-5': pathname == '/staff',
            'light-blue': pathname == '/staff',
          }"
        >
          <Header2
            class="opacity0"
            :class="{ 'fade-in': !menuLoading && menuShow }"
          />
          <router-view
            class="overflow-x-hidden"
            :MenuLoading="menuLoading"
            :Loading2="loading2"
          />
          <div
            class="opacity0"
            :class="{ 'fade-in': !footerLoading && menuShow }"
          >
            <Footer2 />
          </div>
        </div>
      </div>

      <div
        class="absolute_xy text-center crebou_loading noevent"
        :class="{ opacity0: loading2 != 1 }"
      >
        <div class="opacity0" :class="{ 'fade-in': loading2 == 1 }">
          <img
            src="@/assets/img/crebou_loading.png"
            class="crebou filter-shadow"
            @load="loading2 == 2 ? (loading2 = 1) : ''"
          />
          <div class="noto9 f-12 f-blue">
            Loading
            <span v-for="i in 5" :key="i" :class="'opacity0 dot' + i">.</span>
          </div>
        </div>
      </div>
    </v-app>
    <div v-if="!loading2">
      <img
        :src="require('@/assets/img/' + i)"
        v-for="i in imgs"
        :key="i"
        class="absolute t-0 l-0 opacity0 noevent"
        style="width: 1px; height: 1px"
      />
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Header2 from "@/components/header2.vue";
import Footer from "@/components/footer.vue";
import Footer2 from "@/components/footer2.vue";
import FooterMenu from "@/mixins/footerMenu";
import d from "@/d";


export default {
  mixins: [FooterMenu],
  data() {
    return {
      show: false,
      userpath: false,
      drawer: false,
      loading: true,
      menuLoading: true, 
      footerLoading: true,
      pathname: "",
      loading2: 2, 
      imgs: ["top_bgimg.webp"],
      menuShow: 1,
    };
  },
  components: {
    Header,
    Header2,
    Footer,
    Footer2,
  },
  async created() {
    this.pathname = location.pathname;
    await this.$store.dispatch("user/login");
  },
  async mounted() {
    this.resize();
    if (location.pathname.indexOf("/user") != -1) {
      this.userpath = true;
    }
    this.show = true;

    if (location.pathname.indexOf("/user") != -1) {
      this.menuShow = 0;
    }

    window.onload = async () => {
      this.loading = false;
      if (
        location.hash == "#staff" ||
        location.pathname.indexOf("/user") != -1 ||
        location.pathname.indexOf("/estate/") != -1
      ) {
        this.menuLoading = false;
      }
      this.loading2 = 0;
      this.$refs.main.style.position = "relative";
      this.$refs.main.style.height = "auto";

      await d.sleep(2500);

      this.menuLoading = false;

      this.footerLoading = false;

      this.imgs = [
        "9999.svg",
        "application1.webp",
        "application2.webp",
        "application3.webp",
        "bg01.svg",
        "bg_house.webp",
        "buy1.svg",
        "buy2.svg",
        "buy3.svg",
        "company.webp",
        "crebou.png",
        "crebou_loading.png",
        "food1.jpg",
        "food2.jpg",
        "food3.jpg",
        "food4.jpg",
        "footer.svg",
        "frame.png",
        "front1.svg",
        "front2.svg",
        "front3.svg",
        "front4.svg",
        "fuki1.svg",
        "fuki10.svg",
        "fuki2.svg",
        "fuki3.svg",
        "fuki4.svg",
        "fuki5.svg",
        "fuki6.svg",
        "fuki7.svg",
        "fuki8.svg",
        "fuki9.svg",
        "house1.svg",
        "house10.svg",
        "house11.svg",
        "house12.svg",
        "house13.svg",
        "house14.svg",
        "house15.svg",
        "house16.svg",
        "house2.svg",
        "house3.svg",
        "house4.svg",
        "house5.svg",
        "house6.svg",
        "house7.svg",
        "house8.svg",
        "house9.svg",
        "icon1_0.svg",
        "icon1_1.svg",
        "icon1_2.svg",
        "icon1_3.svg",
        "icon1_4.svg",
        "icon2_0.svg",
        "icon2_1.svg",
        "icon2_2.svg",
        "icon2_3.svg",
        "icon3_0.png",
        "icon3_1.svg",
        "icon3_2.svg",
        "illust1.jpg",
        "illust2.jpg",
        "illust3.jpg",
        "illust4.jpg",
        "line_house.svg",
        "loupe.svg",
        "management1.webp",
        "management2.webp",
        "management3.webp",
        "management4.webp",
        "map.svg",
        "navi2.jpg",
        "net1.jpg",
        "noimage.jpg",
        "page_img01.jpg",
        "page_img02.jpg",
        "pattern.webp",
        "point.webp",
        "pr.svg",
        "rogo.png",
        "room1.jpg",
        "room2.jpg",
        "room4.jpg",
        "room5.jpg",
        "room6.png",
        "sale01.jpg",
        "sale02.jpg",
        "sale03.png",
        "satei.png",
        "sec01_01.jpg",
        "sec02_01.jpg",
        "sec02_02.jpg",
        "sec02_03.jpg",
        "sec04_01.png",
        "sec04_02.png",
        "sec05_01.jpg",
        "sec07_01.svg",
        "sec07_02.svg",
        "sec07_03.svg",
        "sec08_01.svg",
        "sec08_02.png",
        "sec10_1.svg",
        "sec10_2.svg",
        "sec10_3.svg",
        "sec10_4.svg",
        "sec10_5.svg",
        "sec11_01.svg",
        "sec11_02.svg",
        "sec11_03.svg",
        "sec12_01.png",
        "sec12_02.png",
        "sky.jpg",
        "slider_point.svg",
        "staff1.jpg",
        "staff2.jpg",
        "staff3.jpg",
        "staff4.jpg",
        "tintai1.jpg",
        "tintai4.jpg",
        "top_bgimg.webp",
        "yasagata.jpg",
      ];
    };
  },
  methods: {
    resize() {
      // ここからリサイズの対応
      let vw = window.innerWidth;
      window.addEventListener("resize", () => {
        if (vw === window.innerWidth) {
          // 画面の横幅にサイズ変動がないので処理を終える
          return;
        }

        // 画面の横幅のサイズ変動があった時のみ高さを再計算する
        vw = window.innerWidth;
        this.setFillHeight();
      });

      // 実行
      this.setFillHeight();
    },
    setFillHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
    top() {
      window.scroll({ top: 0, behavior: "smooth" });
    },
  },
  watch: {
    async $route() {
      this.pathname = location.pathname;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/app.scss";

.loading {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 0;
  height: 0;
  pointer-events: none !important;
}

.main {
  position: fixed;
  height: 100vh;
  width: 100vw;
}

.crebou {
  animation: 2s linear infinite crebou;
}
@keyframes crebou {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.crebou_loading {
  height: 300px;
}

@for $i from 1 through 5 {
  .dot#{$i} {
    animation: 2.5s linear forwards dot#{$i};
  }
}

@keyframes dot1 {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dot2 {
  0% {
    opacity: 0;
  }
  35% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dot3 {
  0% {
    opacity: 0;
  }
  55% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dot4 {
  0% {
    opacity: 0;
  }
  75% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dot5 {
  0% {
    opacity: 0;
  }
  85% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
</style>