
import d from "@/d"
export default {
  data() {
    return {
      scroY: 0,
      set_position:0,
      up:1,
    };
  },
  async mounted() {
    await d.sleep(1000)
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scroY = window.pageYOffset / 1.5;
      if (this.set_position < document.documentElement.scrollTop) {
        this.up=0
      } else {
       this.up=1
      }

      this.set_position = document.documentElement.scrollTop;
    },
  },
};